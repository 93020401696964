/* eslint-disable max-len */
import { encodeUri, request, makeQuery } from "../../src";
import { ApiAdminOrder } from "../types";

export interface ListOrdersOptions {
  page?: number;
  perPage?: number;
  ["orderSort.SortField"]?: "default" | "id";
  ["orderSort.Direction"]?: "asc" | "desc";
  customerTypeFilter?: "all" | "onlyB2B" | "onlyB2C";
  countryType?: "other" | "czech" | "eu";
  orderStatusType?: "created" | "paid" | "offerCreated" | "pending" | "accepted" | "sent" | "toBeShipped" | "completed" | "declined" | "declinedForRefund";
  ["orderSortRange.CreatedAtRange.From"]?: string;
  ["orderSortRange.CreatedAtRange.To"]?: string;
  ["orderSortRange.PriceRange.From"]?: number;
  ["orderSortRange.PriceRange.To"]?: number;
  orderPaymentStatusType?: string;
  term?: string;
  useEmail?: boolean;
  useName?: boolean;
}

/**
* send **GET** request to **\/Ecommerce/order**
*/

export const listOrdersRequest = (ctx: {} = {}, options: ListOrdersOptions) => request<ApiAdminOrder[]>({
  method: "GET",
  path: encodeUri`/ecommerce/order?` + makeQuery("orderSort.SortField", "orderSort.Direction", "customerTypeFilter", "countryType", "orderStatusType", "orderSortRange.CreatedAtRange.From", "orderSortRange.CreatedAtRange.To", "orderSortRange.PriceRange.From", "orderSortRange.PriceRange.To", "orderPaymentStatusType", "term", "useEmail", "useName")(options["orderSort.SortField"], options["orderSort.Direction"], options.customerTypeFilter, options.countryType, options.orderStatusType, options["orderSortRange.CreatedAtRange.From"], options["orderSortRange.CreatedAtRange.To"], options["orderSortRange.PriceRange.From"], options["orderSortRange.PriceRange.To"], options.orderPaymentStatusType, options.term, options.useEmail, options.useName),
  ctx
}, {
  page: options.page,
  perPage: options.perPage
});
